import React from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizedLink } from 'gatsby-theme-i18n';
import useElementOnScreen from '../../utils/useElementOnScreen';
import useZoomEffect from '../../utils/useZoomEffect';
import DiscoverButton from '../buttons/DiscoverButton';
import * as Colors from '../Colors';

type Offer = {
  id: string;
  title: string;
  description: string;
  ref: React.RefObject<HTMLDivElement>;
  isInView: boolean;
};

const themes: Record<string, Record<string, string>> = {
  innovation: {
    class: Colors.BLACK_ON_YELLOW,
    pseudoClass: Colors.BLACK_ON_YELLOW_PSEUDO_CLASS,
  },
  agility: {
    class: Colors.WHITE_ON_BLACK,
    pseudoClass: Colors.WHITE_ON_BLACK_PSEUDO_CLASS,
  },
  tech: {
    class: Colors.WHITE_ON_BLUE,
    pseudoClass: Colors.WHITE_ON_BLUE_PSEUDO_CLASS,
  },
  people: {
    class: Colors.BLACK_ON_LIGHTBLUE,
    pseudoClass: Colors.BLACK_ON_LIGHTBLUE_PSEUDO_CLASS,
  },
};

const Offers: React.VoidFunctionComponent = () => {
  const { t } = useTranslation('offers');

  const content = React.useMemo(() => {
    const result: Offer[] = [];
    const offers = t<string, { [key: string]: Offer }>('offers', {
      returnObjects: true,
      defaultValue: {},
    });
    Object.keys(offers).forEach((key) => {
      const offer = offers[key];
      offer.id = key;
      result.push(offer);
    });
    return result;
  }, [t]);

  const [scrolledElement, animatedElement] = useZoomEffect<HTMLDivElement, HTMLHeadingElement>();

  const offerElements: Record<string, [React.RefObject<HTMLDivElement>, boolean]> = {
    innovation: useElementOnScreen<HTMLDivElement>(),
    agility: useElementOnScreen<HTMLDivElement>(),
    tech: useElementOnScreen<HTMLDivElement>(),
    people: useElementOnScreen<HTMLDivElement>(),
  };

  return (
    <section id="offers-section">
      <div className="overflow-hidden bg-reacteev-blue text-white h-md:lg:h-screen h-md:lg:snap-start">
        <div
          ref={scrolledElement}
          className="container flex h-full flex-col justify-center px-4 py-12 text-center sm:px-8 h-md:lg:py-0"
        >
          <h3 ref={animatedElement} className="mb-10 origin-[50%_75%_0px]">
            {t('title')}
          </h3>
          <LocalizedLink
            className="mb-4 text-xl font-medium sm:text-2xl"
            title={t('subtitle')}
            to="/#offers"
          >
            {t('subtitle')}
          </LocalizedLink>
        </div>
      </div>
      <div id="offers" className="h-lg:lg:h-screen h-lg:lg:snap-start">
        <div className="flex h-full flex-col justify-center">
          {content.map((offer) => (
            <div key={offer.id} className={`h-full ${themes[offer.id].class}`}>
              <div
                ref={offerElements[offer.id][0]}
                className={`${
                  offerElements[offer.id][1] ? 'animate-slidein' : ''
                } container flex h-full max-w-4xl translate-y-12 flex-wrap content-center items-center p-4 opacity-0 animate-delay-200 sm:p-8 lg:flex-nowrap h-lg:lg:py-0`}
              >
                <div className="text-left lg:mr-32">
                  <h3 className="m-0 mb-4 text-left leading-tight">{offer.title}</h3>
                  <p className="font-medium">{offer.description}</p>
                </div>
                <div className="mt-4 py-4 lg:mt-0 lg:py-0 lg:text-right">
                  <DiscoverButton
                    href={`/offers/${offer.id}/`}
                    title={offer.title}
                    pseudoClass={themes[offer.id].pseudoClass}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Offers;
