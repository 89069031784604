import React from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizedLink } from 'gatsby-theme-i18n';
import useElementOnScreen from '../../utils/useElementOnScreen';
import SvgDownArrow from '../../images/icons/downArrow.inline.svg';

type IntroductionProps = {
  linkTo: string;
};

const Introduction: React.VoidFunctionComponent<IntroductionProps> = ({ linkTo }) => {
  const { t } = useTranslation('landing');
  const [animatedElement, isInView] = useElementOnScreen<HTMLDivElement>();

  return (
    <div className="fixed flex h-screen w-full">
      <header className="relative flex h-screen w-full snap-start flex-wrap justify-center bg-reacteev-blue text-center text-white">
        <div
          className={`translate-y-12 self-center px-4 opacity-0 animate-delay-75 sm:px-8 ${
            isInView ? 'animate-slidein' : ''
          }`}
          ref={animatedElement}
        >
          <h1>{t('introduction.title')}</h1>
          <h2 className="xl:text-6xl">{t('introduction.subtitle')}</h2>
        </div>
        <LocalizedLink
          className="absolute bottom-12 animate-bounce text-center"
          title={t('introduction.discover')}
          to={linkTo}
        >
          <SvgDownArrow className="mx-auto block" />
          <p className="mt-3 font-medium">{t('introduction.discover')}</p>
        </LocalizedLink>
      </header>
    </div>
  );
};

export default Introduction;
