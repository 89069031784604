import { StaticImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { useTranslation } from 'react-i18next';
import SeeMoreButton from '../buttons/SeeMoreButton';
import { WHITE_ON_BLACK, WHITE_ON_BLACK_PSEUDO_CLASS } from '../Colors';

const TheyTalkAboutUs: React.VoidFunctionComponent = () => {
  const { t } = useTranslation('landing');
  const INIT_ITEMS = 2;
  const INC_ITEMS = 2;

  type InformationEntrepriseImageType = {
    allFile: {
      nodes: {
        childImageSharp: {
          original: {
            src: string;
          };
        };
      }[];
    };
  };
  const informationEntrepriseImage = useStaticQuery<InformationEntrepriseImageType>(graphql`
    query {
      allFile(filter: { name: { eq: "information-entreprise-183-july-sept-2022" } }) {
        nodes {
          childImageSharp {
            original {
              src
            }
          }
        }
      }
    }
  `);

  // Create a react component and set it in "references" constant below.
  const references = React.useMemo(() => {
    const forbes = ({ title, alt }: { title: string; alt: string }) => {
      return (
        <a
          href="https://www.forbes.fr/brandvoice/agilite-et-design-fiction-voir-plus-loin-et-agir-plus-vite-avec-reacteev/"
          target="_blank"
          rel="noreferrer"
        >
          <StaticImage
            src="../../images/references/forbes.png"
            alt={alt}
            title={alt}
            placeholder="blurred"
            className="mx-auto w-[350px] border-0 xs:w-[376px] sm:w-[525px] md:w-[560px]"
          />
          <div className="my-8">{title}</div>
        </a>
      );
    };
    const lesEchos = ({ title, alt }: { title: string; alt: string }) => {
      return (
        <a
          href="https://solutions.lesechos.fr/ils-en-parlent/c/lagilite-dentreprise-racontee-par-le-cabinet-de-conseil-reacteev-36295/"
          target="_blank"
          rel="noreferrer"
        >
          <StaticImage
            src="../../images/references/les-echos.jpg"
            alt={alt}
            title={alt}
            placeholder="blurred"
            className="mx-auto w-[350px] border-0 xs:w-[376px] sm:w-[525px] md:w-[560px]"
          />
          <div className="my-8">{title}</div>
        </a>
      );
    };
    const challenges = ({ title, alt }: { title: string; alt: string }) => {
      return (
        <a
          href="https://www.challenges.fr/economie/reacteev-pour-ameliorer-l-agilite-des-grands-comptes_827599"
          target="_blank"
          rel="noreferrer"
        >
          <StaticImage
            src="../../images/references/challenges-reacteev-pour-ameliorer-l-agilite-des-grands-comptes.jpg"
            alt={alt}
            title={alt}
            placeholder="blurred"
            className="mx-auto w-[350px] border-0 xs:w-[376px] sm:w-[525px] md:w-[560px]"
          />
          <div className="my-8">{title}</div>
        </a>
      );
    };
    const informationEntreprise = ({ title, alt }: { title: string; alt: string }) => {
      const informationEntrepriseImageSrc =
        informationEntrepriseImage.allFile.nodes[0].childImageSharp.original.src;
      return (
        <a href={informationEntrepriseImageSrc} target="_blank" rel="noreferrer">
          <StaticImage
            src="../../images/references/information-entreprise-183-july-sept-2022.png"
            alt={alt}
            title={alt}
            placeholder="blurred"
            className="mx-auto w-[350px] border-0 xs:w-[376px] sm:w-[525px] md:w-[560px]"
          />
          <div className="my-8">{title}</div>
        </a>
      );
    };
    const demainEntreprendre = ({ title }: { title: string }) => {
      return (
        <>
          <iframe
            width="560"
            height="315"
            className="w-[350px] border-0 xs:w-[376px] sm:w-[525px] md:w-[560px]"
            src="https://www.youtube.com/embed/5KQ_kqoeHsI"
            title={title}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; fullscreen; gyroscope; picture-in-picture"
          ></iframe>
          <div className="my-8">{title}</div>
        </>
      );
    };
    const enjeuxEtPriorite = ({ title }: { title: string }) => {
      return (
        <>
          <iframe
            width="560"
            height="315"
            className="relative left-0 top-0 w-[350px] overflow-hidden border-0 xs:w-[376px] sm:w-[525px] md:w-[560px]"
            title={title}
            src="https://www.dailymotion.com/embed/video/x80tw5y"
            allow="fullscreen"
          ></iframe>
          <div className="my-8">{title}</div>
        </>
      );
    };
    return [
      {
        id: 'forbes',
        reference: forbes({
          title: t('theyTalkAboutUs.forbes.title'),
          alt: t('theyTalkAboutUs.forbes.alt'),
        }),
      },
      {
        id: 'lesEchos',
        reference: lesEchos({
          title: t('theyTalkAboutUs.lesEchos.title'),
          alt: t('theyTalkAboutUs.lesEchos.alt'),
        }),
      },
      {
        id: 'challenges',
        reference: challenges({
          title: t('theyTalkAboutUs.challenges.title'),
          alt: t('theyTalkAboutUs.challenges.alt'),
        }),
      },
      {
        id: 'informationEntreprise',
        reference: informationEntreprise({
          title: t('theyTalkAboutUs.information-entreprise.title'),
          alt: t('theyTalkAboutUs.information-entreprise.alt'),
        }),
      },
      {
        id: 'demainEntreprendre',
        reference: demainEntreprendre({ title: t('theyTalkAboutUs.demainEntreprendre') }),
      },
      {
        id: 'enjeuxEtPriorite',
        reference: enjeuxEtPriorite({ title: t('theyTalkAboutUs.enjeuxEtPriorite') }),
      },
    ];
  }, [informationEntrepriseImage.allFile.nodes, t]);

  const [nbDisplayedItems, setDisplayedItems] = React.useState(INIT_ITEMS);

  return (
    <section id="references">
      <div className="snap-start overflow-hidden bg-black pb-12 text-center text-white xl:flex xl:min-h-screen xl:flex-col xl:justify-center xl:pb-0">
        <h3 className="mb-10 py-8 sm:py-16 xl:pb-6 xl:pt-8">{t('theyTalkAboutUs.title')}</h3>
        <div className="mb-8 flex flex-wrap items-center justify-evenly">
          {references.map(({ id, reference }, index) => {
            return (
              <div className={index >= nbDisplayedItems ? 'hidden' : ''} key={id}>
                {reference}
              </div>
            );
          })}
        </div>
        {nbDisplayedItems < references.length ? (
          <SeeMoreButton
            text={t('theyTalkAboutUs.load-more-button')}
            buttonClass={WHITE_ON_BLACK}
            pseudoClass={WHITE_ON_BLACK_PSEUDO_CLASS}
            handleClick={() => setDisplayedItems(nbDisplayedItems + INC_ITEMS)}
          />
        ) : undefined}
      </div>
    </section>
  );
};

export default TheyTalkAboutUs;
